import i18next from "i18next";

const processGroup = (groupName: string) => {
  if (groupName && typeof groupName === "string") {
    try {
      // example of groupName: {"en":"General","ar":"عام"}
      const translatedGroup = JSON.parse(groupName);
      return i18next.language === "en"
        ? translatedGroup.en
        : translatedGroup.ar;
    } catch (e) {
      return groupName;
    }
  }
};

export default processGroup;
