import axios, { AxiosRequestConfig } from "axios";
import http from "config/httpComman";

export const controller = new AbortController();
export const signal = { signal: controller.signal };
const SpeechLogHttp = axios.create({
  baseURL:
    "https://globi.uniithra.com.sa/GtSpeechlogConnector/api/S_RqmIntegrator",
  headers: {
    "Content-type": "application/json",
  },
});

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  metadata?: {
    startTime: Date;
  };
}

SpeechLogHttp.interceptors.request.use(
  (config: CustomAxiosRequestConfig) => {
    config.metadata = { startTime: new Date() };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

SpeechLogHttp.interceptors.response.use(
  async (response) => {
    const startTime = (response.config as any).metadata.startTime;
    const endTime = new Date();
    const timeTaken = ((endTime as any) - startTime) as any;

    await gacaRecordingService.logEvent({
      for: "SpeechLogHttp",
      request: response.config,
      response: response,
      takenTime: timeTaken,
      statusCode: response.status,
    });

    return response;
  },
  async (error) => {
    if (error.config && error.config.metadata) {
      const startTime = error.config.metadata.startTime;
      const endTime = new Date();
      const timeTaken = ((endTime as any) - startTime) as any;
      await gacaRecordingService.logEvent({
        for: "SpeechLogHttp",
        request: error.config,
        response: error.response,
        error: error.message,
        takenTime: timeTaken,
        statusCode: error.response?.status,
      });
      return Promise.resolve(new Error(error.message));
    }

    return Promise.reject(error);
  }
);
export interface ITicketStart {
  interactionId: string;
  interactionType: string;
  mediaType: string;
  agentId: string;
  agentName?: string;
  agentUserName: string;
  agentDepartment?: string;
  siteId: string;
  siteName?: string;
  stationNumber?: string;
  stationMachineName?: string;
  customerNumber?: string;
  customerName?: string;
  language?: string;
  email: string;
  actor?: string;
  ticketTimeout?: number;
  pauseAllActiveTickets?: boolean;
  stopAllActiveTickets?: boolean;
  customData01?: string;
  customData02?: string;
  customData03?: string;
  customData04?: string;
  customData05?: string;
  customData06?: string;
  customData07?: string;
  customData08?: string;
  customData09?: string;
  customData10?: string;
}
export interface ITicketPause {
  interactionId: string;
  siteId: string;
  siteName?: string;
  stationNumber?: string;
  stationMachineName?: string;
}
export interface ITicketResume {
  interactionId: string;
  siteId: string;
  siteName?: string;
  stationNumber?: string;
  stationMachineName?: string;
}
export interface ITicketStop {
  interactionId: string;
  interactionType: string;
  mediaType: string;
  agentId: string;
  agentName: string;
  agentUserName: string;
  agentDepartment?: string;
  siteId: string;
  siteName?: string;
  stationNumber?: string;
  stationMachineName?: string;
  customerNumber?: string;
  customerName?: string;
  language?: string;
  email: string;
  actor?: string;
  AgentText?:
    | Array<{
        Time: string;
        Text: string;
      }>
    | string;
  CustomerText?:
    | Array<{
        Time: string;
        Text: string;
      }>
    | string;
  customData01?: string;
  customData02?: string;
  customData03?: string;
  customData04?: string;
  customData05?: string;
  customData06?: string;
  customData07?: string;
  customData08?: string;
  customData09?: string;
  customData10?: string;
}

export const gacaGroups = ["gaca", "gaca_video_call"];

export const gacaMustTagChannels = [
  "50502001702C145108607B68ABE3C8",
  "50502001702C145B19C4EF0B5C08A1",
  "50502001702C1455881DDC52FE19CF",
  "F0502001702C1450D9236A8E540423",
  "F0502001702C14534A3B6B61D4CC4C",
  "F0502001702C1453B97688ED09215B",
];

export default class gacaRecordingService {
  static async logEvent(data: any) {
    return await http.post("/genericLogs", data, signal);
  }
  static async ticketStart(data: ITicketStart) {
    return await SpeechLogHttp.post("/TicketStart", data, signal);
  }
  static async ticketPause(data: ITicketPause) {
    return await SpeechLogHttp.post("/ticketPause", data, signal);
  }
  static async ticketResume(data: ITicketResume) {
    return await SpeechLogHttp.post("/ticketResume", data, signal);
  }
  static async ticketStop(data: ITicketStop) {
    return await SpeechLogHttp.post("/ticketStop  ", data, signal);
  }
}
