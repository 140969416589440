export enum TabsPermission {
  VIEW_CAMPAIGN_TAB = "VIEW_CAMPAIGN_TAB",
  VIEW_SURVEY_TAB = "VIEW_SURVEY_TAB",
  VIEW_FLOW_TAB = "VIEW_FLOW_TAB",
  VIEW_DASHBOARD_TAB = "VIEW_DASHBOARD_TAB",
  VIEW_INBOX_TAB = "VIEW_INBOX_TAB",
  VIEW_REPORT_TAB = "VIEW_REPORT_TAB",
  VIEW_COMMUNITY_TAB = "VIEW_COMMUNITY_TAB",
  VIEW_SUBSCRIPTION_TAB = "VIEW_SUBSCRIPTION_TAB",
  VIEW_REQUEST_TAB = "VIEW_REQUEST_TAB",
  VIEW_FLOW_EDITOR_TAB = "VIEW_FLOW_EDITOR_TAB",
  VIEW_FLOW_PREVIEW_TAB = "VIEW_FLOW_PREVIEW_TAB"
}
