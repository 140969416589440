import { filter, find, groupBy, isEmpty, map, sortBy } from "lodash";
import {
  agentProps,
  agentSetting,
  channelsAgentsProps,
  groupAgentsProps,
  roleProps,
  tabPermissionProps,
} from "./type";

import { TabPermissionOperation } from "enums/agents";
import {
  CREATE_AGENT,
  CREATE_PERMISSONS_AGENTS,
  DELETE_AGENT,
  ENABLE_ALL_CHANNELS,
  GET_AGENTS,
  GET_AGENTS_BY_SCOPE,
  GET_AGENTS_FILTERED,
  GET_CHANNELS_AGENTS,
  GET_GROUPS_AGENTS,
  GET_ROLE_AGENTS,
  GET_SESSION_TYPE_AGENTS,
  GET_TAB_PERMISSONS_AGENTS,
  NEW_SESSION_TYPES,
  RESEND_EMAIL_AGENT,
  SETTING_GET_AGENTS,
  UPDATE_AGENT_COMMUNITY_STATUS,
  UPDATE_GROUPS_AGENTS,
  UPDATE_GROUPS_SEETING_AGENTS,
  UPDATE_PERMISSONS_AGENTS,
  UPDATE_PERMISSONS_SETTING_AGENTS,
  UPDATE_ROLE_AGENTS,
  UPDATE_SHIFTS_AGENTS,
  UPDATE_STATUS_AGENTS,
  UPDATE_TAB_PERMISSONS_AGENTS,
} from "./types";
import { updateGroups, updatePermissions } from "./utils";
import processGroup from "utils/translateGroupName";

const initialState: agentSetting = {
  agents: [],
  agentObject: {},
  agentsFiltered: [],
  settingAgent: [],
  channels: [],
  new_channels: [],
  roles: [],
  new_roles: [],
  groups: [],
  new_groups: [],
  tab_permission: [],
  new_tab_permission: [],
  onlineAgents: 0,
  sessionTypes: {},
  targetPermissons: [],
  newSessionTypes: {},
  isEnableAllChannels: false,
};

export default function agentReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_AGENTS: {
      return {
        ...state,
        agents: sortBy(payload, ["visibility", "firstName", "lastName"]),
        onlineAgents: payload.filter(
          (agent: agentProps) => agent.visibility === 1
        ).length,
      };
    }
    case GET_AGENTS_BY_SCOPE: {
      return {
        ...state,
        agentObject: payload.reduce((acc: any, agent: any) => {
          acc[agent.agentId] = agent;
          return acc;
        }, {}),
      };
    }
    case GET_AGENTS_FILTERED: {
      return {
        ...state,
        agentsFiltered: sortBy(payload, [
          "visibility",
          "firstName",
          "lastName",
        ]),
      };
    }
    case UPDATE_AGENT_COMMUNITY_STATUS: {
      return {
        ...state,
        agentObject: {
          ...state.agentObject,
          [payload?.agentID]: {
            ...state.agentObject[payload?.agentID],
            communityConnected: payload?.connected,
          },
        },
      };
    }
    case SETTING_GET_AGENTS: {
      return {
        ...state,
        settingAgent: sortBy(payload, ["visibility", "firstName", "lastName"]),
        onlineAgents: payload.filter(
          (agent: agentProps) => agent.visibility === 1
        ).length,
      };
    }
    case CREATE_AGENT: {
      let newState: any = { ...state };
      return {
        ...state,
        [payload?.nameState]: [
          payload?.data,
          ...newState?.[payload?.nameState],
        ],
      };
    }
    case RESEND_EMAIL_AGENT: {
      return {
        ...state,
      };
    }
    case DELETE_AGENT: {
      let newState: any = { ...state };
      return {
        ...state,
        [payload?.nameState]: newState?.[payload?.nameState].filter(
          (item: agentProps) => {
            return item.agentId !== payload?.id;
          }
        ),
      };
    }
    case GET_CHANNELS_AGENTS: {
      return {
        ...state,
        channels: payload,
        new_channels: [...payload].map((item: channelsAgentsProps) => {
          return {
            id: item.sessionType,
            value: item.sessionType,
          };
        }),
      };
    }
    case GET_SESSION_TYPE_AGENTS: {
      let res = groupBy([...payload], ({ engineID }: any) => engineID);
      // res["all"]=res["undefined"]
      delete res["undefined"];
      return {
        ...state,
        sessionTypes: res,
      };
    }
    case GET_ROLE_AGENTS: {
      return {
        ...state,
        roles: payload,
        new_roles: [...payload].map((item: roleProps) => {
          return {
            id: item.roleID,
            value: item.name,
            text: item.name,
          };
        }),
      };
    }
    case GET_TAB_PERMISSONS_AGENTS: {
      return {
        ...state,
        tab_permission: payload,
        new_tab_permission: [...payload].map((item: tabPermissionProps) => {
          return {
            id: item.ID,
            value: item?.name?.replaceAll("_", " ")?.toLowerCase(),
            text: item.name,
          };
        }),
      };
    }
    case GET_GROUPS_AGENTS: {
      return {
        ...state,
        groups: payload,
        new_groups: [...payload].map((item: groupAgentsProps) => {
          return {
            id: item.groupID,
            value: processGroup(item.groupName),
          };
        }),
      };
    }
    case UPDATE_STATUS_AGENTS: {
      let status = "ACTIVE";
      if (payload?.data?.agent_status === 1) {
        status = "INACTIVE";
      }
      let newState: any = { ...state };
      return {
        ...state,
        [payload?.nameState]: [...newState?.[payload?.nameState]].map(
          (item: agentProps) =>
            item.agentId === payload?.data?.agent_id
              ? Object.assign({}, item, {
                  statusId: payload?.data?.agent_status,
                  status,
                })
              : item
        ),
      };
    }
    case UPDATE_ROLE_AGENTS: {
      let newState: any = { ...state };
      return {
        ...state,
        [payload?.nameState]: [...newState?.[payload?.nameState]].map(
          (item: agentProps) =>
            item.agentId === payload?.data?.agent_id
              ? Object.assign({}, item, { roleID: payload?.data?.role_id })
              : item
        ),
      };
    }
    case UPDATE_TAB_PERMISSONS_AGENTS: {
      let newState: any = { ...state };
      newState?.[payload?.nameState].map((item: agentProps) => {
        if (item.agentId === payload?.data?.agent_id) {
          if (payload?.data?.operation === TabPermissionOperation.ADD) {
            Object.assign(item, {
              tabPermission: [
                ...item?.tabPermission,
                { ID: payload?.data?.tabPermission },
              ],
            });
          } else {
            let newTabPermission = filter(
              [...item?.tabPermission],
              (i) => i?.ID !== payload?.data?.tabPermission
            );
            Object.assign(item, { tabPermission: newTabPermission });
          }
        }
        return item;
      });
      return {
        ...state,
        [payload?.nameState]: newState?.[payload?.nameState],
      };
    }
    case UPDATE_PERMISSONS_AGENTS: {
      const newPermissions = updatePermissions(state.agents, payload);
      return {
        ...state,
        agents: [...state.agents].map((item: agentProps) =>
          item.agentId === payload?.agent_id
            ? Object.assign({}, item, {
                channelPermissions: newPermissions,
              })
            : item
        ),
      };
    }
    case UPDATE_PERMISSONS_SETTING_AGENTS: {
      const newPermissions = updatePermissions(
        state.settingAgent,
        payload?.data
      );
      let resAgent = [...state.settingAgent].map((item: agentProps) =>
        item.agentId === payload?.data?.agent_id
          ? Object.assign({}, item, {
              channelPermissions: [...newPermissions, ...payload?.responseData],
            })
          : item
      );
      return {
        ...state,
        settingAgent: resAgent,
      };
    }
    case UPDATE_GROUPS_AGENTS: {
      const newGroups = updateGroups(state.agents, payload);
      return {
        ...state,
        agents: [...state.agents].map((item: agentProps) =>
          item.agentId === payload?.agent_id
            ? Object.assign({}, item, { groups: newGroups })
            : item
        ),
      };
    }
    case UPDATE_GROUPS_SEETING_AGENTS: {
      const newGroups = updateGroups(state.settingAgent, payload);
      let resAgent = [...state.settingAgent].map((item: agentProps) =>
        item.agentId === payload?.agent_id
          ? Object.assign({}, item, { groups: newGroups })
          : item
      );
      return {
        ...state,
        settingAgent: resAgent,
      };
    }
    case CREATE_PERMISSONS_AGENTS: {
      let newPermissions: any = [];
      if (
        payload?.permission_not_included?.length !== 0 ||
        payload.permissions_included.length !== 0
      ) {
        newPermissions = filter(
          [...state.targetPermissons],
          (item: string) =>
            !(payload.permission_not_included || []).includes(item)
        );
        map(payload?.permissions_included, (item: any) => {
          let res = find(newPermissions, (i) => i?.permissionId === item) || "";
          if (isEmpty(res)) {
            newPermissions.push(item);
          }
        });
      }
      return {
        ...state,
        targetPermissons: newPermissions,
      };
    }
    case UPDATE_SHIFTS_AGENTS: {
      return {
        ...state,
      };
    }
    case NEW_SESSION_TYPES: {
      return {
        ...state,
        newSessionTypes: payload,
      };
    }
    case ENABLE_ALL_CHANNELS: {
      return {
        ...state,
        isEnableAllChannels: payload,
      };
    }
    default:
      return state;
  }
}
