import { has } from "lodash";
import { categoriesProps, tagsProps, tagsSetting } from "./type";
import {
  ADD_TAG_TO_SESSION,
  CREATE_TAGS,
  DELETE_TAGS,
  GET_CATEGORIES,
  GET_CONTEXT_MENU_SESSION_TAGS,
  GET_SESSION_TAGS,
  GET_TAGS,
  REMOVE_TAG_FROM_SESSION,
  UPDATE_TAGS,
} from "./types";
import { findTargetIndex } from "./utils";

const initialState: tagsSetting = {
  tags: {},
  sessionTags: {},
  categories: [],
  new_categories: [],
  selectCategory: null,
  contextMenuTags: {},
};

export default function tagsReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_TAGS:
      return {
        ...state,
        tags: payload,
      };
    case GET_SESSION_TAGS: {
      let sessionTags = payload.res;
      let allTags: any = state.tags;

      // loop over the all the tags I have and add the sessionId to to traget tags
      for (let tag in sessionTags) {
        for (let i = 0; i < sessionTags[tag].length; i++) {
          for (let j = 0; j < allTags[tag].length; j++) {
            if (sessionTags[tag][i].id === allTags[tag][j].id) {
              allTags[tag][j].sessions = [payload.sessionId];
              break;
            }
          }
        }
      }
      return {
        ...state,
        sessionTags: payload.res ? payload.res : state.tags,
        tags: allTags,
      };
    }
    case GET_CONTEXT_MENU_SESSION_TAGS: {
      let sessionTags = payload.res;
      let allTags: any = state.tags;

      // loop over the all the tags I have and add the sessionId to to traget tags
      for (let tag in sessionTags) {
        for (let i = 0; i < sessionTags[tag].length; i++) {
          for (let j = 0; j < allTags[tag].length; j++) {
            if (sessionTags[tag][i].id === allTags[tag][j].id) {
              allTags[tag][j].sessions = [payload.sessionId];
              break;
            }
          }
        }
      }
      return {
        ...state,
        contextMenuTags: allTags,
      };
    }
    case GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
        new_categories: [...payload]?.map((item: categoriesProps) => {
          return {
            id: item.id,
            value: item.catalog_name,
            title: item.catalog_name,
          };
        }),
      };
    case CREATE_TAGS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          [payload[0]?.catalog_name]: has(state.tags, payload[0]?.catalog_name)
            ? [...state.tags[payload[0]?.catalog_name], payload[0]]
            : [payload[0]],
        },
      };
    }
    case UPDATE_TAGS: {
      let resIndex: string = findTargetIndex({ ...state.tags }, payload?.id);
      return {
        ...state,
        tags: {
          ...state.tags,
          [resIndex]: [...state.tags[resIndex]]?.map((item: tagsProps) =>
            item.id === payload?.id
              ? Object.assign({}, item, { tag_name: payload?.tag_name || "" })
              : item
          ),
        },
      };
    }

    case ADD_TAG_TO_SESSION: {
      let resIndex: string = findTargetIndex({ ...state.tags }, payload?.id);
      const targetCat = state.tags[resIndex];
      const updatedTags = targetCat?.map((tag) => {
        if (`${tag.id}` === `${payload.id}`) {
          tag.sessions = [payload.sessionId];
        }
        return tag;
      });

      let updatedSessionTags = { ...state.sessionTags };
      if (!updatedSessionTags[resIndex]) {
        updatedSessionTags[resIndex] = [];
      }
      const sessionTagIndex = updatedSessionTags[resIndex].findIndex(
        (tag) => `${tag.id}` === `${payload.id}`
      );
      if (sessionTagIndex !== -1) {
        updatedSessionTags[resIndex][sessionTagIndex].sessions = [
          payload.sessionId,
        ];
      } else {
        updatedSessionTags[resIndex].push({
          id: payload.id,
          sessions: [payload.sessionId],
          groupIDs: undefined,
          catalog_id: "",
          catalog_name: "",
          company_id: "",
          created_at: "",
          tag_name: "",
        });
      }

      return {
        ...state,
        tags: {
          ...state.tags,
          [resIndex]: updatedTags,
        },
        sessionTags: updatedSessionTags,
        selectCategory: resIndex,
      };
    }
    case DELETE_TAGS: {
      let resIndex: string = findTargetIndex({ ...state.tags }, payload?.id);
      return {
        ...state,
        tags: {
          ...state.tags,
          [resIndex]: [...state.tags[resIndex]].filter(
            (item: tagsProps) => item.id !== payload?.id
          ),
        },
        selectCategory: resIndex,
      };
    }

    case REMOVE_TAG_FROM_SESSION: {
      let resIndex: string = findTargetIndex({ ...state.tags }, payload?.id);
      const targetCat = state.tags[resIndex];
      const updatedTags = targetCat?.map((tag) => {
        if (`${tag.id}` === `${payload.id}`) {
          tag.sessions = [];
        }
        return tag;
      });

      let updatedSessionTags = { ...state.sessionTags };
      if (updatedSessionTags[resIndex]) {
        const sessionTagIndex = updatedSessionTags[resIndex].findIndex(
          (tag) => `${tag.id}` === `${payload.id}`
        );
        if (sessionTagIndex !== -1) {
          updatedSessionTags[resIndex].splice(sessionTagIndex, 1);
        }
        if (updatedSessionTags[resIndex].length === 0) {
          delete updatedSessionTags[resIndex];
        }
      }

      return {
        ...state,
        tags: {
          ...state.tags,
          [resIndex]: updatedTags,
        },
        sessionTags: updatedSessionTags,
      };
    }
    default:
      return state;
  }
}
