export const FLOW_STATUS = "FLOW_STATUS";
export const FLOW_CREATE = "FLOW_CREATE";
export const FLOW_FETCH = "FLOW_FETCH";
export const FLOW_DELETE = "FLOW_DELETE";
export const FLOW_UPDATE = "FLOW_UPDATE";
export const FLOW_SEARCH = "FLOW_SEARCH";
export const FLOW_FILTER_TRIGGER = "FLOW_FILTER_TRIGGER";
export const FLOW_UPDATE_STATUS = "FLOW_UPDATE_STATUS";

export const NODES_GET = "NODES_GET";

export const NODES_UPDATE = "NODES_UPDATE";
export const ADD_NODE_TO_CUSTOM_OBJ_NODES = "ADD_NODE_TO_CUSTOM_OBJ_NODES";
//types related to system varS
export const NODES_CONNECTED_VARS = "NODES_CONNECTED_VARS";
export const NODES_UPDATE_CONNECTED_VARS = "NODES_UPDATE_CONNECTED_VARS";
export const DELETE_RULE_UPDATE_CONNECTED_VARS =
  "DELETE_RULE_UPDATE_CONNECTED_VARS";
export const ADD_NODES_CONNECTED_VARS = "ADD_NODES_CONNECTED_VARS";

//types related to jump
export const NODES_CONNECTED_JUMP = "NODES_CONNECTED_JUMP";
export const NODES_UPDATE_CONNECTED_JUMP = "NODES_UPDATE_CONNECTED_JUMP";
export const NODES_DELETE_CONNECTED_JUMP = "NODES_DELETE_CONNECTED_JUMP";
export const NODES_DELETE_GROUP_CONNECTED_JUMP =
  "NODES_DELETE_GROUP_CONNECTED_JUMP";
export const ADD_NODES_CONNECTED_JUMP = "ADD_NODES_CONNECTED_JUMP";

export const NODES_NOTIFIED = "NODES_NOTIFIED";

//types related to userSpace in httpRequest node
export const GET_HTTP_REQUEST_USER_SPACE = "GET_HTTP_REQUEST_USER_SPACE";
export const UPDATE_HTTP_REQUEST_USER_SPACE = "UPDATE_HTTP_REQUEST_USER_SPACE";
export const DELETE_HTTP_REQUEST_USER_SPACE = "DELETE_HTTP_REQUEST_USER_SPACE";

export const UPDATE_SHOW_DRAWER = "UPDATE_SHOW_DRAWER";
export const UPDATE_SELECTED_CONDITION = "UPDATE_SELECTED_CONDITION";

export const ERRORS_RELATED_VARIABLES = "ERRORS_RELATED_VARIABLES";
export const ADD_ERRORS_RELATED_VARIABLES = "ADD_ERRORS_RELATED_VARIABLES";
export const DELETE_ERRORS_RELATED_VARIABLES =
  "DELETE_ERRORS_RELATED_VARIABLES";

export const NODES_FIELDS_ERRORS = "NODES_FIELDS_ERRORS";
export const UPDATE_NODES_FIELDS_ERRORS = "UPDATE_NODES_FIELDS_ERRORS";
export const ADD_NODES_FIELDS_ERRORS = "ADD_NODES_FIELDS_ERRORS";
export const DELETE_NODES_FIELDS_ERRORS = "DELETE_NODES_FIELDS_ERRORS";

export const ADD_FIELDS_ERRORS_REPLY = "ADD_FIELDS_ERRORS_REPLY";
export const UPDATE_NODES_FIELDS_ERRORS_REPLY =
  "UPDATE_NODES_FIELDS_ERRORS_REPLY";

export const UPDATE_FLOW_OPTIONS = "UPDATE_FLOW_OPTIONS";
export const FLOW_CLEAR = "FLOW_CLEAR";
export const GET_ORDERED_NODES = "GET_ORDERED_NODES";
export const UPDATE_ORDERED_NODES = "UPDATE_ORDERED_NODES";
export const UPDATE_ORDERED_NODES_SUB_FLOWS = "UPDATE_ORDERED_NODES_SUB_FLOWS";

export const CUSTOM_LOADING = "CUSTOM_LOADING";
export const CUSTOM_STOP_LOADING = "CUSTOM_STOP_LOADING";

export const DISPLAY_ERROR_MODAL = "DISPLAY_ERROR_MODAL";
export const STOP_DISPLAY_ERROR_MODAL = "STOP_DISPLAY_ERROR_MODAL";

export const DISPLAY_TEMPLATE_MODAL = "DISPLAY_TEMPLATE_MODAL";
export const STOP_DISPLAY_TEMPLATE_MODAL = "STOP_DISPLAY_TEMPLATE_MODAL";

export const UPDATE_ALL_VARIABLES = "UPDATE_ALL_VARIABLES";
export const ADD_HTTP_REQUEST_USER_SPACE = "ADD_HTTP_REQUEST_USER_SPACE";

export const FETCH_SUB_FLOWS = "FETCH_SUB_FLOWS";
export const DELETE_SUB_FLOW = "DELETE_SUB_FLOW";
export const ADD_SUB_FLOW = "ADD_SUB_FLOW";
export const EDIT_SUB_FLOW = "EDIT_SUB_FLOW";

//types related to SubFlow
export const NODES_CONNECTED_SUB_FLOW = "NODES_CONNECTED_SUB_FLOW";
export const ADD_NODES_CONNECTED_SUB_FLOW = "ADD_NODES_CONNECTED_SUB_FLOW";
export const UPDATE_NODES_CONNECTED_SUB_FLOW =
  "UPDATE_NODES_CONNECTED_SUB_FLOW";
export const DELETE_NODES_CONNECTED_SUB_FLOW =
  "DELETE_NODES_CONNECTED_SUB_FLOW";
export const STARTING_POSITION = "STARTING_POSITION"
export const UPDATE_MODE = "UPDATE_MODE"
export const FETCH_PREVIEW_OPERATIONS = "FETCH_PREVIEW_OPERATIONS"
export const UPDATE_PREVIEW_OPERATIONS = "UPDATE_PREVIEW_OPERATIONS"
export const REQUEST_REVIEW_PREVIEW_OPERATIONS = "REQUEST_REVIEW_PREVIEW_OPERATIONS"

export const CLASSIFICATION_OPERATIONS_INCLUDED_REVIEW = "CLASSIFICATION_OPERATIONS_INCLUDED_REVIEW"
export const ACCEPT_REVIEW_OPERATION = "ACCEPT_REVIEW_OPERATION"
export const ACCEPT_REVIEW_ADD_DELETE_OPERATION = "ACCEPT_REVIEW_ADD_DELETE_OPERATION"
export const FINISH_REVIEW_OPERATIONS = "FINISH_REVIEW_OPERATIONS"