import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { AppContext } from "App";
import { ReactComponent as LogoutIcon } from "assets/general-icon/logout.svg";
import { ReactComponent as SupportIcon } from "assets/general-icon/support.svg";
import { AppConfig } from "enums/config";
import { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/auth/actions";
import { authSelector, UserProfile } from "redux/auth/selector";
import LanguageButton from "./LanguageButton";
interface Props {}

const ActionsButton = (props: Props) => {
  const [t, theme, minPcMatch] = useContext(AppContext);
  const dispatch = useDispatch();
  const { token }: UserProfile = useSelector(authSelector);

  const supportIconDisplay = useMemo(
    () => (
      <SupportIcon
        width={"1.2rem"}
        height={"1.2rem"}
        fill={
          minPcMatch
            ? theme.palette.general.offWhite
            : theme.palette.general.darkPrimary
        }
      />
    ),
    [
      minPcMatch,
      theme.palette.general.offWhite,
      theme.palette.general.darkPrimary,
    ]
  );

  const logoutIconDisplay = useMemo(
    () => (
      <LogoutIcon
        width={"1.2rem"}
        height={"1.2rem"}
        fill={
          minPcMatch
            ? theme.palette.general.offWhite
            : theme.palette.general.darkPrimary
        }
      />
    ),
    [
      minPcMatch,
      theme.palette.general.offWhite,
      theme.palette.general.darkPrimary,
    ]
  );

  const handleLogout = () => {
    dispatch(logout(token));
  };

  return (
    <Grid
      container
      justifyContent="space-around"
      sx={{
        background: minPcMatch
          ? theme.palette.light
          : theme.palette.general.white,
        borderRadius: AppConfig.BORDER_RADIUS_MIN,
        p: "0.2567em .3em",
        border: minPcMatch
          ? "none"
          : `1px solid ${theme.palette.general.grayDark}`,
      }}
    >
      <Grid
        item
        container
        xs={12}
        lg={process.env.REACT_APP_ENV !== "cppa" ? 4 : 6}
        p={minPcMatch ? ".5em" : "0 .6em"}
        sx={{
          borderRight: minPcMatch
            ? "none"
            : `1px solid ${theme.palette.general.grayLighter}`,
        }}
        justifyContent={minPcMatch ? "flex-start" : "space-around"}
      >
        <LanguageButton />
      </Grid>
      {process.env.REACT_APP_ENV !== "cppa" && (
        <Grid
          item
          container
          xs={12}
          lg={4}
          p={minPcMatch ? ".5em" : "0 .6em"}
          sx={{
            borderRight: minPcMatch
              ? "none"
              : `1px solid ${theme.palette.general.grayLighter}`,
          }}
          justifyContent={"space-around"}
        >
          <Button
            variant="text"
            endIcon={!minPcMatch ? supportIconDisplay : ""}
            startIcon={minPcMatch ? supportIconDisplay : ""}
            sx={{
              p: 0,
              textTransform: "none",
              fontSize: minPcMatch ? "1rem" : ".85rem",
              "&:hover": { bgcolor: "transparent" },
              color: minPcMatch
                ? theme.palette.general.offWhite
                : theme.palette.general.darkPrimary,
              fontWeight: 500,
              justifyContent: minPcMatch ? "flex-start" : "auto",
            }}
            disableRipple
            disableElevation
            fullWidth={minPcMatch}
            href={`mailto:support@${process.env.REACT_APP_NAME ?? ""}app.com`}
            target="_blank"
          >
            {t("support")}
          </Button>
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        lg={process.env.REACT_APP_ENV !== "cppa" ? 4 : 6}
        p={minPcMatch ? ".5em" : "0 .6em"}
        justifyContent={"space-around"}
      >
        <Button
          variant="text"
          endIcon={!minPcMatch ? logoutIconDisplay : ""}
          startIcon={minPcMatch ? logoutIconDisplay : ""}
          sx={{
            p: 0,
            textTransform: "none",
            "&:hover": { bgcolor: "transparent" },
            fontSize: minPcMatch ? "1rem" : ".85rem",
            color: minPcMatch
              ? theme.palette.general.offWhite
              : theme.palette.general.darkPrimary,
            fontWeight: 500,
            justifyContent: minPcMatch ? "flex-start" : "auto",
          }}
          disableRipple
          disableElevation
          fullWidth={minPcMatch}
          onClick={handleLogout}
          title={t("logout")}
          id="logout-button"
        >
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {t("logout")}
          </span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ActionsButton;
