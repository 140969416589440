import { SessionType } from "enums/sessions";
import store from "../store";
import { currentFilters, SessionMessages } from "./type";

export const updateSessions = ({
  payload,
  sessionActive,
  sessionInactive,
  activeCheck,
  inactiveCheck,
}: any) => {
  let newActive = sessionActive;
  let newInactive = sessionInactive;
  if (inactiveCheck) {
    // handle signed and unasigned session
    if (payload.sessionStatus === SessionType.UNASSIGNED) {
      newInactive.splice(newInactive, 1, payload);
    } else {
      newInactive.splice(newInactive, 1);
      newActive = [payload, ...newActive];
    }
  }

  if (activeCheck) {
    // handle signed and unasigned session
    if (payload.sessionStatus === SessionType.ASSIGNED) {
      newActive.splice(newActive, 1, payload);
    } else {
      newActive.splice(newActive, 1);
      newInactive = [payload, ...newInactive];
    }
  }
  //   console.log({ payload, newInactive, newActive, inactiveCheck, activeCheck })
  return { newInactiveSession: newInactive, newActiveSession: newActive };
};

export const twitterRecieveMsg = (data: {
  messagesIndex: number;
  payload: any;
  sessionMsgs: any;
  messages: any;
}) => {
  const { messagesIndex, payload, sessionMsgs, messages } = data;
  let parentId = payload.VendorParentIDs?.split(",");
  parentId = parentId[parentId.length - 1];
  if (messagesIndex !== -1) {
    // find parent index in messages
    let parentIndex = sessionMsgs
      .map((item: any) => item.vendorMessageKey)
      .indexOf(parentId);

    // find target reply
    let targetReplyIndex = messages[parentIndex]?.replies
      .map((item: any) => item.messageKey)
      .indexOf(payload.messageKey);
    // console.log({
    //   messageReply: messages[parentIndex].replies,
    //   payload,
    //   targetReplyIndex,
    // });

    if (targetReplyIndex !== -1) {
      // remove the old tweet and add the new one
      messages[parentIndex]?.replies?.pop();
    }

    messages[parentIndex]?.replies?.push(payload);
    // console.log({ messages: messages[parentIndex] });
  } else {
    // check if this new twitter messages
    let parentIndex = sessionMsgs
      .map((item: any) => item?.vendorMessageKey)
      .indexOf(parentId);
    // console.log("22", { parentIndex, sessionMsgs });
    if (parentIndex !== -1) {
      messages[parentIndex]?.replies?.push(payload);
    }
  }

  return messages;
};

export const updateReplyStatus: (
  session: SessionMessages,
  payload: SessionMessages
) => SessionMessages = (session, payload) => {
  let sessionMesages = session;
  // console.log({ sessionMesages, payload });
  return sessionMesages;
};

// set predefine valie
export const setPredefine: (formData: FormData) => FormData = (
  formData: FormData
) => {
  // split the message to check each part seperate
  let storeData: any = store.getState();
  let sendMsg: any = formData.get("message");

  let predefine = storeData?.preDefine?.selectedMessage?.message;
  if (predefine && sendMsg) {
    formData.set("predefinedMsgID", storeData?.preDefine?.selectedMessage?.id);
    /* let splitMsg = predefine.split("\n");
    let msgId = storeData?.preDefine?.selectedMessage?.predefinedKey;
    loop: for (let i = 0; i < splitMsg.length; i++) {
      if (!sendMsg.includes(splitMsg[i])) {
        formData.set("predefinedKey", "");
        break loop;
      } else {
        formData.set("predefinedKey", msgId);
      }
    } */
  }

  return formData;
};

// handle sessionOpen event based on the rule of the current user
export const updateTotalSessionsByUserSessionOpen = (
  payload: any,
  sessionInactive: any,
  sessionActive: any,
  state: any
) => {
  let newActiveCount = state.activeCount;
  let newInactiveCount = state.inactiveCount;
  // next ifs include agent & supervisor
  if (
    (payload.type === "session" || payload.type === "sessionEvent") &&
    payload.sessionStatus === SessionType.UNASSIGNED
  ) {
    newInactiveCount = newInactiveCount + 1;
  }

  if (
    (payload.type === "session" || payload.type === "sessionEvent") &&
    payload.sessionStatus === SessionType.ASSIGNED
  ) {
    newActiveCount = newActiveCount + 1;
  }
  return { newActiveCount, newInactiveCount };
};

//sessionTaken has been handled in the reducer case AGENT_TAKE_SESSION_SUCCESS
//sessionClosed has been handled in the reducer case SESSION_CLOSED_SUCCESS

export const filterIncomingEvents = (
  incomingSession: SessionMessages,
  filters: currentFilters,
  isEnableSearch: boolean = false,
  actionType?: string
) => {
  // func return  boolean before add session to current lists
  // get current filter passed along with event
  // check if events is
  // TODO: apply filter to name and user name

  //return true if no action type (currently only SESSION_OPEN_SUCCESS passes action type)
  if (!actionType) return true;

  // Set filter conditions for incoming sessions
  // 1. check if filter channel
  const isChannelIncluded =
    filters?.ChannelIDs && filters?.ChannelIDs?.length > 0
      ? (filters.ChannelIDs as string[])?.includes(
          incomingSession?.channel?.channelID
        )
      : true;

  //2. check if filter search (username or client name)
  const isSearchMatched =
    filters?.userName || filters?.clientName
      ? incomingSession?.clientName
          ?.toLowerCase()
          .includes(filters?.clientName?.toLowerCase() || "") ||
        incomingSession?.username
          ?.toLowerCase()
          .includes(filters?.userName?.toLowerCase() || "")
      : true;

  return isChannelIncluded && (isEnableSearch ? isSearchMatched : true);
};

export const filterSessions: (
  groupfilter: string[],
  agentFilter: number[],
  sessionsActive: any | null,
  sessionInactive: any | null,
  sessionsEscalated: any | null,
  sessionsClosed: any | null,
  sessionsUnassignEscalated: any | null
) => {
  sessionActiveFilter: object;
  sessionInactiveFilter: object;
  sessionEscalatedFilter: object;
  sessionClosedFilter: object;
  sessionsUnassignEscalatedFilter: object;
} = (
  groupfilter,
  agentFilter,
  sessionsActive,
  sessionInactive,
  sessionsEscalated,
  sessionsClosed,
  sessionsUnassignEscalated
) => {
  let filterVal = agentFilter.length > 0 || groupfilter.length > 0;

  if (filterVal) {
    // Active filter
    if (sessionsActive) {
      for (let item in sessionsActive) {
        let groupMatch = false;
        let agentMatch = false;

        //Active and queued session agent check
        if (
          agentFilter.length > 0 &&
          agentFilter.includes(sessionsActive[item].agentID)
        ) {
          agentMatch = true;
        }

        // Active and queued session group filter
        if (
          groupfilter.length > 0 &&
          groupfilter.includes(sessionsActive[item].groupID)
        ) {
          groupMatch = true;
        }

        if (filterVal && !groupMatch && !agentMatch) {
          delete sessionsActive[item];
        }
      }
    }

    // inActive filter
    if (sessionInactive) {
      for (let item in sessionInactive) {
        let groupMatch = false;

        // Active and queued session group filter
        if (
          groupfilter.length > 0 &&
          groupfilter.includes(sessionInactive[item].groupID)
        ) {
          groupMatch = true;
        }

        if (filterVal && !groupMatch) {
          delete sessionInactive[item];
        }
      }
    }

    // Escalated session
    if (sessionsEscalated) {
      for (let item in sessionsEscalated) {
        let groupMatch = false;
        let agentMatch = false;
        // Escalated session agent check
        if (
          agentFilter.length > 0 &&
          agentFilter.includes(sessionsEscalated[item].agentID)
        ) {
          agentMatch = true;
        }

        // Escalated session group filter
        if (
          groupfilter.length > 0 &&
          groupfilter.includes(sessionsEscalated[item].groupID)
        ) {
          groupMatch = true;
        }

        if (filterVal && !groupMatch && !agentMatch) {
          delete sessionsEscalated[item];
        }
      }
    }

    // Unassigned Escalated session
    if (sessionsUnassignEscalated) {
      for (let item in sessionsUnassignEscalated) {
        let groupMatch = false;
        let agentMatch = false;
        // Escalated session agent check
        if (
          agentFilter.length > 0 &&
          agentFilter.includes(sessionsUnassignEscalated[item].agentID)
        ) {
          agentMatch = true;
        }

        // Escalated session group filter
        if (
          groupfilter.length > 0 &&
          groupfilter.includes(sessionsUnassignEscalated[item].groupID)
        ) {
          groupMatch = true;
        }

        if (filterVal && !groupMatch && !agentMatch) {
          delete sessionsUnassignEscalated[item];
        }
      }
    }

    // Closed session
    if (sessionsClosed) {
      for (let item in sessionsClosed) {
        let groupMatch = false;
        let agentMatch = false;
        // Closed session agent check
        if (
          agentFilter.length > 0 &&
          agentFilter.includes(sessionsClosed[item].agentID)
        ) {
          agentMatch = true;
        }

        // Closed session group filter
        if (
          groupfilter.length > 0 &&
          groupfilter.includes(sessionsClosed[item].groupID)
        ) {
          groupMatch = true;
        }

        if (filterVal && !groupMatch && !agentMatch) {
          delete sessionsClosed[item];
        }
      }
    }
  }

  return {
    sessionActiveFilter: sessionsActive,
    sessionInactiveFilter: sessionInactive,
    sessionEscalatedFilter: sessionsEscalated,
    sessionClosedFilter: sessionsClosed,
    sessionsUnassignEscalatedFilter: sessionsUnassignEscalated,
  };
};

export const updateTweetsAndReplies = (
  state: any,
  payload: any,
  propertyToUpdate: string,
  newValue: any
) => {
  return {
    ...state,
    sessionMessages: {
      ...state.sessionMessages,
      tweets: {
        ...state.sessionMessages.tweets,
        [propertyToUpdate]:
          state.sessionMessages.tweets.messageKey === payload.messageKey
            ? newValue
            : state.sessionMessages.tweets[propertyToUpdate],
        replies:
          state.sessionMessages.tweets.messageKey !== payload.messageKey
            ? state.sessionMessages.tweets.replies.map((reply: any) => {
                if (reply.messageKey === payload.messageKey) {
                  return {
                    ...reply,
                    [propertyToUpdate]: newValue,
                  };
                } else {
                  return reply;
                }
              })
            : state.sessionMessages.tweets.replies,
      },
    },
  };
};

export const getReactionProperty = (type: string, deleteValue: any) => {
  const typeToPropertyMap: any = {
    like: { property: "isLiked", value: true },
    unlike: { property: "isLiked", value: false },
    repost: { property: "isReposted", value: true },
    unrepost: { property: "isReposted", value: false },
    delete: { property: "deletedAt", value: deleteValue },
  };

  return typeToPropertyMap[type] || {};
};
