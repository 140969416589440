import { genKpiProps, kpiSetting } from "./type";

import {
  CREATE_KPI,
  DELETE_KPI,
  GET_GENERATED_KPIS,
  GET_KPIS,
  GET_METRICS,
  UPDATE_KPIS,
  UPDATE_METRICS,
} from "./types";

const initialState: kpiSetting = {
  kpis: [],
  agentFilter: [],
  genratedKpi: [],
  agentTrace: {},
  metrics: {
    ces: [
      {
        key: "ces_is_required",
        value: "1",
        label: "CES  Required",
        type: "BOOLEAN",
      },
      {
        key: "ces_show",
        value: "new",
        label: "CES Show",
        type: "STRING",
      },
    ],
    csat: [
      {
        key: "csat_type",
        value: "5",
        label: "CSAT Type",
        type: "INT",
      },
      {
        key: "csat_is_required",
        value: "1",
        label: "CSAT Required",
        type: "BOOLEAN",
      },
      {
        key: "csat_show",
        value: "text",
        label: "CSAT Show",
        type: "STRING",
      },
      {
        key: "csat_questions",
        value: "default_question",
        label: "CSAT Question",
        type: "STRING",
      },
      {
        key: "csat_keywords",
        value: "default_keywords",
        label: "CSAT Keywords",
        type: "STRING",
      },
    ],
    fcr: [
      {
        key: "fcr_type",
        value: "new",
        label: "FCR Type",
        type: "STRING",
      },
      {
        key: "fcr_is_required",
        value: "1",
        label: "FCR Required",
        type: "BOOLEAN",
      },
      {
        key: "fcr_show",
        value: "text",
        label: "FCR Show",
        type: "STRING",
      },
    ],
    nps: [
      {
        key: "nps_is_required",
        value: "1",
        label: "NPS Required",
        type: "BOOLEAN",
      },
      {
        key: "nps_show",
        value: "1",
        label: "NPS Show",
        type: "BOOLEAN",
      },
      {
        key: "nps_questions",
        value: "default_question",
        label: "NPS Question",
        type: "STRING",
      },
      {
        key: "nps_keywords",
        value: "default_keywords",
        label: "NPS Keywords",
        type: "STRING",
      },
    ],
  },
};

export const agentTraceFun = (kpi: any, agentTrace: any) => {
  // we need this to catch all the selected agents
  // for specific card id for different kpis
  if (agentTrace[kpi?.Name]) {
    agentTrace = {
      ...agentTrace,
      [kpi.Name]: {
        ...agentTrace[kpi.Name],
        [kpi.ID]: kpi.AgentIDs,
      },
    };
  } else {
    agentTrace = {
      ...agentTrace,
      [kpi.Name]: {
        [kpi.ID]: kpi.AgentIDs,
      },
    };
  }

  return agentTrace;
};

export default function kpiReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_KPIS:
      return {
        ...state,
        kpis: payload.map((kpi: any) => {
          return { id: kpi.Name, value: kpi.DataType, text: kpi.Name };
        }),
      };
    case CREATE_KPI:
      let agentTrace = agentTraceFun(payload, state.agentTrace);
      let selectedAgent: any = state.agentFilter;
      if (selectedAgent[payload?.KPIid]) {
        let newData = new Set([
          ...selectedAgent[payload.KPIid],
          ...(payload?.AgentIDs ?? []),
        ]);
        selectedAgent[payload.KPIid] = [...newData];
      } else {
        selectedAgent[payload.KPIid] = [...(payload?.AgentIDs ?? [])];
      }

      return {
        ...state,
        genratedKpi: [payload, ...state.genratedKpi],
        agentTrace: agentTrace,
        agentFilter: selectedAgent,
      };

    case GET_GENERATED_KPIS: {
      let selectedAgent: any = {};
      let agentTrace: any = {};
      let res = [...payload];
      res.sort((a: any, b: any) => {
        return a.CreatedAt < b.CreatedAt
          ? 1
          : a.CreatedAt > b.CreatedAt
          ? -1
          : 0;
      });
      res?.map((item: any) => {
        // we need this to cach all the selected agents in
        // all the kpis
        if (selectedAgent[item?.KPIid]) {
          let newData = new Set([
            ...selectedAgent[item.KPIid],
            ...(item?.AgentIDs ?? []),
          ]);
          selectedAgent[item.KPIid] = [...newData];
        } else {
          selectedAgent[item.KPIid] = [...(item?.AgentIDs ?? [])];
        }

        agentTrace = agentTraceFun(item, agentTrace);
        return item;
      });
      return {
        ...state,
        genratedKpi: res,
        agentFilter: selectedAgent,
        agentTrace: agentTrace,
      };
    }

    case UPDATE_KPIS: {
      let agentTrace = state.agentTrace;
      let id = payload.id;
      delete payload.id;

      let generatedKpi = [...state.genratedKpi].map((item: genKpiProps) => {
        if (item.ID === id) {
          if (payload.AgentIDs) {
            agentTrace = agentTraceFun(
              { ...item, AgentIDs: payload.AgentIDs },
              agentTrace
            );
          }
          return Object.assign({}, item, { ID: id, ...payload });
        }
        return item;
      });

      let selectedAgent: any = state.agentFilter;
      if (selectedAgent[payload?.KPIid]) {
        let newData = new Set([
          ...selectedAgent[payload.KPIid],
          ...(payload?.AgentIDs ?? []),
        ]);
        selectedAgent[payload.KPIid] = [...newData];
      } else {
        selectedAgent[payload.KPIid] = [...(payload?.AgentIDs ?? [])];
      }

      return {
        ...state,
        genratedKpi: generatedKpi,
        agentTrace,
        agentFilter: selectedAgent,
      };
    }
    case DELETE_KPI: {
      let agentTrace: any = state.agentTrace;
      let agentFilter: any = state.agentFilter;

      let genratedKpi = state.genratedKpi.filter((item: any) => {
        if (item.ID === payload) {
          // remove agent id in filter
          delete agentFilter[item.KPIid];

          // remove agent trace
          delete agentTrace[item.Name][item.ID];
        }

        // remove agent trace
        if (Object.keys(agentTrace[item.Name]).length === 0) {
          delete agentTrace[item.Name];
        }
        return item.ID !== payload;
      });

      return {
        ...state,
        genratedKpi: genratedKpi,
        agentTrace,
        agentFilter,
      };
    }
    case GET_METRICS: {
      return {
        ...state,
        metrics: payload,
      };
    }
    case UPDATE_METRICS: {
      return {
        ...state,
        metrics: {
          ...state.metrics,
          payload,
        },
      };
    }
    default:
      return state;
  }
}
